import deployment from './deployment.json';

export const env = deployment.env;
export const branch = deployment.branch;
export const commitSha = deployment.commitSha;
export const appVersion = "0.1.4";

export const wpPluginDownload = () => {
  return `${appUrl}/static/rapaygo-for-woocommerce-1.0.18.zip`
}

export const xummConfig = (() => {
  switch (deployment.env) {
    case 'mock':
      return {};
    case 'local':
      return {'api-key': '1b144141-440b-4fbc-a064-bfd1bdd3b0ce', 
        'xrp_network': 'testnet',
        'xrp_endpoint': 'wss://s.altnet.rippletest.net:51233',
        'xrp_endpoint_explorer': 'https://testnet.xrpl.org'};
    case 'dev':
      return {'api-key': '1b144141-440b-4fbc-a064-bfd1bdd3b0ce', 
      'xrp_network': 'testnet',
      'xrp_endpoint': 'wss://s.altnet.rippletest.net:51233',
      'xrp_endpoint_explorer': 'https://testnet.xrpl.org'};
    case 'prd':
      return {'api-key': 'ba11d6dc-43c5-474f-8f03-0f76b92c3613', 
      'xrp_network': 'testnet',
      'xrp_endpoint': 'wss://s.altnet.rippletest.net:51233',
      'xrp_endpoint_explorer': 'https://testnet.xrpl.org'};
  }
})();

export const backendBaseUrl = (() => {
  switch (deployment.env) {
    case 'mock':
      return 'http://localhost:3100'; //use the mock server 
    case 'local':
      return 'http://localhost:5000'; //use the local api server
    case 'dev':
      return 'https://devapi.xurlpay.org/v1';
    case 'prd':
      return 'https://api.xurlpay.org/v1';
  }
})();

export const xurlBaseUrl = (shop_id) => {
  switch (deployment.env) {
    case 'mock':
      return 'http://localhost:3100'; //use the mock server 
    case 'local':
      return `http://${shop_id}.localhost:5005/xurlapi/xurl`; 
    case 'dev':
      return `https://${shop_id}.shops.xurlpay.org/xurlapi/xurl`;
    case 'prd':
      return 'https://api.xurlpay.org/v1';
  }
};


export const appUrl = (() => {
  switch (deployment.env) {
    case 'mock':
      return 'https://localhost:3001'; //use the mock server
    case 'local':
      return 'https://localhost:3001'; //use the local api server
    case 'dev':
      return 'https://dev.xurlpay.org';
    case 'prd':
      return 'https://xurlpay.org';
  }
})();

export const whitepaperUrl = (() => {
  switch (deployment.env) {
    case 'mock':
      return 'https://github.com/claytantor/xrpl-poc-python/blob/dev/docs/whitepaper.md'; //use the mock server
    case 'local':
      return 'https://github.com/claytantor/xrpl-poc-python/blob/dev/docs/whitepaper.md'; //use the local api server
    case 'dev':
      return 'https://github.com/claytantor/xrpl-poc-python/blob/dev/docs/whitepaper.md';
    case 'prd':
      return 'https://github.com/claytantor/xrpl-poc-python/blob/main/docs/whitepaper.md';
  }
})();

export const deploymentInfo = (() => {
  deployment.dateVal = new Date(parseInt(deployment.timestamp, 10)*1000);
  return deployment;
})();

export const deploymentEnv = (() => {
  return deployment.env;
})();

export const currencyLang = {
      'USD': 'en-US',
      'EUR': 'en-US',
      'GBP': 'en-GB',
      'AUD': 'en-AU',
      'CAD': 'en-CA',
      'CHF': 'fr-FR',
      'CNY': 'zh-CN',
      'HKD': 'zh-HK',
      'JPY': 'ja-JP',
      'KRW': 'ko-KR',
      'NZD': 'en-NZ',
      'SGD': 'en-SG',
      'THB': 'th-TH',
      'TRY': 'tr-TR',
      'ZAR': 'en-ZA',
      'MXN': 'es-MX', 
  }

  export const makeCurrencyList = () => {

    console.log('makeCurrencyList');
    let currencyList = [];
    for (const [key, value] of Object.entries(currencyLang)) {
      // console.log(`${key}: ${value}`);
      currencyList.push({value: key, label: key, lang: value});
    }
    return currencyList;
  };
  
  export const currenciesLookup = {
    "AED": "United Arab Emirates Dirham",
    "AFN": "Afghan Afghani",
    "ALL": "Albanian Lek",
    "AMD": "Armenian Dram",
    "ANG": "Netherlands Antillean Gulden",
    "AOA": "Angolan Kwanza",
    "ARS": "Argentine Peso",
    "AUD": "Australian Dollar",
    "AWG": "Aruban Florin",
    "AZN": "Azerbaijani Manat",
    "BAM": "Bosnia and Herzegovina Convertible Mark",
    "BBD": "Barbadian Dollar",
    "BDT": "Bangladeshi Taka",
    "BGN": "Bulgarian Lev",
    "BHD": "Bahraini Dinar",
    "BIF": "Burundian Franc",
    "BMD": "Bermudian Dollar",
    "BND": "Brunei Dollar",
    "BOB": "Bolivian Boliviano",
    "BRL": "Brazilian Real",
    "BSD": "Bahamian Dollar",
    "BTN": "Bhutanese Ngultrum",
    "BWP": "Botswana Pula",
    "BYN": "Belarusian Ruble",
    "BYR": "Belarusian Ruble",
    "BZD": "Belize Dollar",
    "CAD": "Canadian Dollar",
    "CDF": "Congolese Franc",
    "CHF": "Swiss Franc",
    "CLF": "Unidad de Fomento",
    "CLP": "Chilean Peso",
    "CNH": "Chinese Renminbi Yuan Offshore",
    "CNY": "Chinese Renminbi Yuan",
    "COP": "Colombian Peso",
    "CRC": "Costa Rican Colón",
    "CUC": "Cuban Convertible Peso",
    "CVE": "Cape Verdean Escudo",
    "CZK": "Czech Koruna",
    "DJF": "Djiboutian Franc",
    "DKK": "Danish Krone",
    "DOP": "Dominican Peso",
    "DZD": "Algerian Dinar",
    "EGP": "Egyptian Pound",
    "ERN": "Eritrean Nakfa",
    "ETB": "Ethiopian Birr",
    "EUR": "Euro",
    "FJD": "Fijian Dollar",
    "FKP": "Falkland Pound",
    "GBP": "British Pound",
    "GEL": "Georgian Lari",
    "GGP": "Guernsey Pound",
    "GHS": "Ghanaian Cedi",
    "GIP": "Gibraltar Pound",
    "GMD": "Gambian Dalasi",
    "GNF": "Guinean Franc",
    "GTQ": "Guatemalan Quetzal",
    "GYD": "Guyanese Dollar",
    "HKD": "Hong Kong Dollar",
    "HNL": "Honduran Lempira",
    "HRK": "Croatian Kuna",
    "HTG": "Haitian Gourde",
    "HUF": "Hungarian Forint",
    "IDR": "Indonesian Rupiah",
    "ILS": "Israeli New Sheqel",
    "IMP": "Isle of Man Pound",
    "INR": "Indian Rupee",
    "IQD": "Iraqi Dinar",
    "IRT": "Iranian Toman",
    "ISK": "Icelandic Króna",
    "JEP": "Jersey Pound",
    "JMD": "Jamaican Dollar",
    "JOD": "Jordanian Dinar",
    "JPY": "Japanese Yen",
    "KES": "Kenyan Shilling",
    "KGS": "Kyrgyzstani Som",
    "KHR": "Cambodian Riel",
    "KMF": "Comorian Franc",
    "KRW": "South Korean Won",
    "KWD": "Kuwaiti Dinar",
    "KYD": "Cayman Islands Dollar",
    "KZT": "Kazakhstani Tenge",
    "LAK": "Lao Kip",
    "LBP": "Lebanese Pound",
    "LKR": "Sri Lankan Rupee",
    "LRD": "Liberian Dollar",
    "LSL": "Lesotho Loti",
    "LYD": "Libyan Dinar",
    "MAD": "Moroccan Dirham",
    "MDL": "Moldovan Leu",
    "MGA": "Malagasy Ariary",
    "MKD": "Macedonian Denar",
    "MMK": "Myanmar Kyat",
    "MNT": "Mongolian Tögrög",
    "MOP": "Macanese Pataca",
    "MRO": "Mauritanian Ouguiya",
    "MUR": "Mauritian Rupee",
    "MVR": "Maldivian Rufiyaa",
    "MWK": "Malawian Kwacha",
    "MXN": "Mexican Peso",
    "MYR": "Malaysian Ringgit",
    "MZN": "Mozambican Metical",
    "NAD": "Namibian Dollar",
    "NGN": "Nigerian Naira",
    "NIO": "Nicaraguan Córdoba",
    "NOK": "Norwegian Krone",
    "NPR": "Nepalese Rupee",
    "NZD": "New Zealand Dollar",
    "OMR": "Omani Rial",
    "PAB": "Panamanian Balboa",
    "PEN": "Peruvian Sol",
    "PGK": "Papua New Guinean Kina",
    "PHP": "Philippine Peso",
    "PKR": "Pakistani Rupee",
    "PLN": "Polish Złoty",
    "PYG": "Paraguayan Guaraní",
    "QAR": "Qatari Riyal",
    "RON": "Romanian Leu",
    "RSD": "Serbian Dinar",
    "RUB": "Russian Ruble",
    "RWF": "Rwandan Franc",
    "SAR": "Saudi Riyal",
    "SBD": "Solomon Islands Dollar",
    "SCR": "Seychellois Rupee",
    "SEK": "Swedish Krona",
    "SGD": "Singapore Dollar",
    "SHP": "Saint Helenian Pound",
    "SLL": "Sierra Leonean Leone",
    "SOS": "Somali Shilling",
    "SRD": "Surinamese Dollar",
    "SSP": "South Sudanese Pound",
    "STD": "São Tomé and Príncipe Dobra",
    "SVC": "Salvadoran Colón",
    "SZL": "Swazi Lilangeni",
    "THB": "Thai Baht",
    "TJS": "Tajikistani Somoni",
    "TMT": "Turkmenistani Manat",
    "TND": "Tunisian Dinar",
    "TOP": "Tongan Paʻanga",
    "TRY": "Turkish Lira",
    "TTD": "Trinidad and Tobago Dollar",
    "TWD": "New Taiwan Dollar",
    "TZS": "Tanzanian Shilling",
    "UAH": "Ukrainian Hryvnia",
    "UGX": "Ugandan Shilling",
    "USD": "US Dollar",
    "UYU": "Uruguayan Peso",
    "UZS": "Uzbekistan Som",
    "VEF": "Venezuelan Bolívar",
    "VES": "Venezuelan Bolívar Soberano",
    "VND": "Vietnamese Đồng",
    "VUV": "Vanuatu Vatu",
    "WST": "Samoan Tala",
    "XAF": "Central African Cfa Franc",
    "XAG": "Silver (Troy Ounce)",
    "XAU": "Gold (Troy Ounce)",
    "XCD": "East Caribbean Dollar",
    "XDR": "Special Drawing Rights",
    "XOF": "West African Cfa Franc",
    "XPD": "Palladium",
    "XPF": "Cfp Franc",
    "XPT": "Platinum",
    "YER": "Yemeni Rial",
    "ZAR": "South African Rand",
    "ZMW": "Zambian Kwacha",
    "ZWL": "Zimbabwean Dollar",
  }
  
